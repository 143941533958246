<template>
	<div class="px-2 sm:px-10 py-4">
    <Header v-bind="content.profile" isTitle title="Venture Settings"/>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <div class="flex justify-between items-center">
          <Text size="xl" weight="semibold" color="gray-900" :content="'Venture Info'"/>
          <Icon name="InformationCircleIcon" size="6" color="gray-400"/>
        </div>
        <Text @click="isOpen = !isOpen" size="sm" color="primary-600" :content="isOpen ? 'hide' : 'show'" decoration="underline" custom-class="cursor-pointer mt-2"/>
        <div class="animate__animated" :class="isOpen ? 'animate__fadeIn' : 'animate__fadeOut'">
          <p class="leading-4 mt-4">
            <Text size="xs" color="black" weight="bold" content="Name: " element="span"/>
            <Text size="xs" color="black" weight="base" :content="`An easy way to reference your idea, even if it's not the &#8221official&#8221 name`" element="span"/>
          </p>
          <p class="leading-4 mt-3">
            <Text size="xs" color="black" weight="bold" content="Idea: " element="span"/>
            <Text size="xs" color="black" weight="base" :content="`A brief description of what you're thinking about creating.`" element="span"/>
          </p>
        </div>

      </div>
      <div class="col-span-5">
        <div class="shadow-lg h-fit rounded-md">
          <div class="px-5 py-8 grid grid-cols-1 gap-y-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
            <div v-for="key in Object.keys(content.info)" :key="key" class="w-full">
              <Input v-if="content.info[key].type === 'text'" :label="content.info[key].label" v-model:value="content.info[key].value" :type="content.info[key].type"/>
              <Textarea v-if="content.info[key].type === 'textarea'" :label="content.info[key].label" v-model:value="content.info[key].value" custom-class="w-full"/>
            </div>

          </div>
          <div class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
            <div/>
            <div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
              <Button
                  content="Cancel"
                  :variant="null"
                  customClass="bg-white text-black underline px-2 bg-gray-100"
              />
              <Button
                  content="Save"
                  variant="primary"
                  customClass="ml-4 px-4"
                  :isLoading="isLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Accelerator'"/>
        <Text size="xs" color="black" weight="base" :content="`Change DSCVRY from a tool to a guided step-by-step accelerator process for just $99. You'll get a focused UI, clear goals, milestone-based education, and access to our private Slack channel.`" custom-class="mt-4 leading-5"/>
      </div>
      <div class="col-span-5">
        <div class="h-fit">
          <div class="bg-white border border-gray-300 rounded-md px-5 py-8">
            <div>
              <Text size="sm" weight="semibold" color="black" content="Accelerator Mode"/>
              <div class="flex items-center space-x-5 mt-4">
                <Toggle v-model:value="isAccelerate"/>
                <Text size="sm" color="black" :content="isAccelerate ? 'ON' : 'OFF'"/>
              </div>
              <div v-if="isAccelerate" class="mt-4">
                <Text size="sm" weight="semibold" content="Payment Method"/>
                <div class="space-y-2 mt-3">
                  <div v-for="payment in content.payments" :key="payment.id" class="flex justify-between items-center px-4 py-2 rounded-md bg-gray-200">
                    <div class="flex items-center space-x-2">
                      <Text size="xs" color="black" :content="`Ending in *${payment.last4}`" weight="semibold"/>
                    </div>
                    <Text size="sm" color="primary-600" :content="'edit'" decoration="underline" custom-class="cursor-pointer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Your Venture Team'"/>
        <Text size="xs" color="black" weight="base" :content="`Invite anyone you want to help you on this journey. &#8221Members&#8221 are like co-founders or teammates who can add and edit information. 'Read-only' users are like mentors and advisors who can only see what you're working on, but not edit anything.`" custom-class="mt-4 leading-5"/>
      </div>
      <div class="col-span-5">
        <div class="h-fit rounded-md">
          <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
            <div v-for="contact in content.contacts" :key="contact.type" class="grid grid-cols-4 py-3 px-2">
              <div class="col-span-2">
                <Text size="sm" weight="semibold" color="black" :content="contact.value"/>
              </div>
              <div class="col-span-1">
                <Badge :label="contact.type" :icon-name="contact.icon" isLeft bg-color="gray-500" is-rounded px="3" py="1" text-color="white"/>
              </div>
              <div class="col-span-1 flex justify-end">
                <!--									<a class="px-1">-->
                <!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
                <!--									</a>-->
                <a @click="isOpenRemove = true" class="px-1">
                  <Icon name="TrashIcon" color="red-600" class="hover:text-red-900"/>
                </a>
              </div>
            </div>
          </div>
          <Text size="sm" color="primary-600" content="Invite someone" decoration="underline" custom-class="cursor-pointer mt-5"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Ecosystem'"/>
        <Text size="xs" color="black" weight="base" :content="`Any ecosystems like schools or startup incubators that you've been invited to be a part of will show up here.`" custom-class="mt-4 leading-5"/>
      </div>
      <div class="col-span-5">
        <div class="h-fit rounded-md">
          <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
            <div v-for="eco in content.ecosystems" :key="eco.id" class="grid grid-cols-4 py-3 px-2">
              <div class="col-span-2">
                <Text size="sm" weight="semibold" color="black" :content="eco.name"/>
              </div>
              <div class="col-span-1">
                <Text size="sm" weight="semibold" color="black" :content="`joined ${moment(eco.date).format('M/D/YYYY')}`"/>
              </div>
              <div class="col-span-1 flex justify-end">
                <!--									<a class="px-1">-->
                <!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
                <!--									</a>-->
                <a class="px-1">
                  <Icon name="TrashIcon" color="red-600" class="hover:text-red-900"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
      <div class="col-span-2">
        <Text size="xl" weight="semibold" color="gray-900" :content="'Programs and Cohorts'"/>
        <Text size="xs" color="black" weight="base" :content="`If you've been invited to join a program inside of an ecosystem, it will show up here.`" custom-class="mt-4 leading-5"/>
      </div>
      <div class="col-span-5">
        <div class="h-fit rounded-md">
          <div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
            <div v-for="program in content.programs" :key="program.id" class="grid grid-cols-4 py-3 px-2">
              <div class="col-span-2">
                <Text size="sm" weight="semibold" color="black" :content="program.name"/>
              </div>
              <div class="col-span-1">
                <Text size="sm" weight="semibold" color="black" :content="program.description"/>
              </div>
              <div class="col-span-1 flex justify-end">
                <!--									<a class="px-1">-->
                <!--										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>-->
                <!--									</a>-->
                <a class="px-1">
                  <Icon name="TrashIcon" color="red-600" class="hover:text-red-900"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InviteMember :is-show="isShow" @onClose="isShow = false" @onSubmit="(formGroup) => onSubmitBtn(formGroup)" v-bind="{
			...c.inviteMember,
			isShow,
			isLoading,
			list: state.group.groups,
		}" />
    <DeleteModal
        :is-show="isOpenRemove"
        @onClose="() => isOpenRemove = false"
        :title="`Remove a <b>Teammate?</b>`"
        :description="`Are you sure you want to remove your <b>Range</b>? They will no longer have access to your venture team's data.`"
        yes-btn-text="Remove"
        no-btn-text="Cancel"
        icon='TrashIcon'
        yesBtnVariant='danger'
        noBtnVariant='secondary'
        :on-no-click="() => isOpenRemove = false"
        :on-yes-click="() => onRemove()"
    />
	</div>
</template>

<script>
import EmptyState from '../components/organisms/EmptyState/Range/Range'
import ListViewHeader from '../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton'
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from '../components/organisms/Tables/Simple/Simple.vue'
import Text from '../components/atoms/Text/Text';
import { c } from '../components/constants.js';
import InviteMember from '../components/organisms/Modals/InviteMember/InviteMember.vue'
import DeleteModal from '../components/organisms/Modals/SimpleAlert/SimpleAlert'
import Header from '../components/molecules/PageViewHeader/Profile/Profile'
import Input from '../components/molecules/Inputs/Component/Component'
import Textarea from '../components/molecules/Textareas/Simple/Simple'
import Toggle from '../components/molecules/Toggles/Simple/Simple'
import Badge from '../components/molecules/Badge/Basic/Basic'
import Icon from '../components/atoms/Icons/Icons'
import moment from "moment";
import { get } from 'lodash'

export default {
	components: {
		EmptyState, ListViewHeader, CardWithImage, CardWithBadge, Text, InviteMember, Table, DeleteModal, Header, Input, Textarea, Toggle, Badge, Icon
	},
	data() {
		return {
			content: c.venture_settings,
			isShow: false,
      isOpenRemove: false,
			isOpenTip: true,
      isOpen: true,
      isAccelerate: true,
			isLoading: false,
			get,
			c,
			venture: null,
      moment,
		}
	},
	methods: {
    async onSubmitBtn(item) {
      console.log('onSubmit ====', item);
      if (item?.length > 0) {
        const { inviteContact } = await this.actions.user.inviteContact({
          contacts: [{ email: item[0].value }],
          userId: this.state.currentUser?.id,
          extraData: {
            ventureId: this.venture?.id,
            groupId: item[1]?.value?.id
          }
        });
        console.log(inviteContact, 'inviteContact');
        if (inviteContact) {
          this.isShow = false;
          setTimeout(() => this.actions.alert.showSuccess({ message: 'You\'ve successfully sent your invitation', title: 'Invitation sent' }), 1000)
        }
      }

    },
    onRemove() {
      this.isOpenRemove = false;
      this.actions.alert.showError({title: 'User successfully removed', message: 'You\'ve successfully removed!'})
    }
	},
	async created() {
    await this.actions.group.getGroups();
    console.log(this.state.group.groups, 'groups')
	}
}
</script>
